import React, { useRef } from "react";
import { ReactTyped as Typed } from "react-typed";
import Slider from "react-slick";
import "./App.css";

function App() {
  const website_porto = [
    {
      web_title: "DOUBLE S DOUBLE S",
      web_name: "DOUBLE S AGENCY WEBSITE",
      web_img: "./images/web_projects/doubles.png",
      web_jobdesk: "UI/UX Designer & Front-end Developer",
      web_desc:
        "A sleek and modern company profile website for a creative agency, highlighting their services, portfolio, and brand identity. The website was built to attract potential clients and showcase the agency's creative capabilities.",
      web_link: "https://www.doublescreative.online",
      web_color: "bg-[#0a4251]",
      web_text_color: "text-[#052933]",
      web_button_color: "bg-[#052933]",
    },
    {
      web_title: "GOLDEN PR GOLDEN PR",
      web_name: "GOLDEN PR RESTAURANT WEBSITE",
      web_img: "./images/web_projects/golden.png",
      web_jobdesk: "Fullstack Developer",
      web_desc:
        "Developed a dynamic and user-friendly website showcasing the restaurant's menu, services, and profile. The website includes an admin panel, allowing restaurant staff to update and manage menu items as needed easily.",
      web_link: "https://github.com/Tiaa21/UTSWebPro-Resto-GoldenPR",
      web_color: "bg-[#121a1d]",
      web_text_color: "text-[#a48c64]",
      web_button_color: "bg-[#a48c64]",
    },
    {
      web_title: "RHAPSODIE RHAPSODIE",
      web_name: "RHAPSODIE BOOKING WEBSITE",
      web_img: "./images/web_projects/rhapsodie.png",
      web_jobdesk: "UI/UX Designer & Fullstack Developer",
      web_desc:
        "Created a comprehensive booking and company profile website for a music space agency. The website allows users to book private music spaces across various branches and locations, providing a seamless booking experience.",
      web_link: "https://github.com/VgTan/Rhapsodie-Music-Space",
      web_color: "bg-[#3e8ec7]",
      web_text_color: "text-[#1d537a]",
      web_button_color: "bg-[#1d537a]",
    },
    {
      web_title: "3AMVALINE 3AMVALINE",
      web_name: "3AMVALINE INFORMATION WEBSITE",
      web_img: "./images/web_projects/valine.png",
      web_jobdesk: "UI/UX Designer & Fullstack Developer",
      web_desc:
        "Valine - your ultimate destination for Valorant lineups. Designed to streamline the process of finding your perfect lineup by agent and map, Valine empowers Valorant players to discover and master their preferred strategies.",
      web_link: "https://valine3am.vercel.app",
      web_color: "bg-[#ba3a46]",
      web_text_color: "text-[#651b22]",
      web_button_color: "bg-[#651b22]",
    },
    {
      web_title: "NASI CIA NASI CIA",
      web_name: "NASI CIA BOOK MENU WEBSITE",
      web_img: "./images/web_projects/nasicia.png",
      web_jobdesk: "UI/UX Designer & Fullstack Developer",
      web_desc:
        "A dynamic website for a client that makes the dining experience effortless. Users can seamlessly order food for dining in through an intuitive interface, complete with a secure payment gateway for hassle-free transactions.",
      web_link: "https://github.com/VgTan/NasiCiaFix/tree/master",
      web_color: "bg-[#f2d115]",
      web_text_color: "text-[#ffffff]",
      web_button_color: "bg-[#b09603]",
    },
  ];

  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    vertical: true,
    verticalSwiping: true,
    arrows: false,
  };

  var settings_2 = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    vertical: true,
    verticalSwiping: true,
    arrows: false,
    rtl: true,
  };

  const sliderRef = useRef(null);

  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const previous = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  var settings_website = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
  };

  return (
    <div className="font-poppin">
      <div
        className="flex items-center justify-center h-screen bg-cover bg-no-repeat"
        style={{ backgroundImage: "url('./images/home_bg.png')" }}
      >
        <div className="justify-center text-center">
          <p className="text-3xl md:text-8xl text-[#F4F3EE] font-bold pb-4">
            Chyntia <span className="text-[#E0AFA0]">Irawan</span>
          </p>
          <p className="text-xl md:text-3xl text-[#F4F3EE] font-regular">
            I'm a{" "}
            <Typed
              strings={[
                "Web Developer",
                "UI/UX Designer",
                "Graphic Designer",
                "Content Creator",
              ]}
              typeSpeed={50}
              backSpeed={70}
              loop
            />
          </p>
        </div>
        <div className="absolute bottom-16 md:bottom-10 right-10">
          <div className="flex flex-col space-y-4 items-center justify-center z-40">
            <a target="_blank" href="https://www.instagram.com/chyntiairawann/">
              <svg
                className="w-6 h-6 md:w-8 md:h-8 text-[#F4F3EE] transition ease-in-out duration-200 hover:-translate-y-1 hover:scale-110 hover:text-[#E0AFA0]"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="M3 8a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5V8Zm5-3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3H8Zm7.597 2.214a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2h-.01a1 1 0 0 1-1-1ZM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/in/chyntia-irawan-676486225/"
            >
              <svg
                class="w-6 h-6 md:w-8 md:h-8 text-[#F4F3EE] transition ease-in-out duration-200 hover:-translate-y-1 hover:scale-110 hover:text-[#E0AFA0]"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.51 8.796v1.697a3.738 3.738 0 0 1 3.288-1.684c3.455 0 4.202 2.16 4.202 4.97V19.5h-3.2v-5.072c0-1.21-.244-2.766-2.128-2.766-1.827 0-2.139 1.317-2.139 2.676V19.5h-3.19V8.796h3.168ZM7.2 6.106a1.61 1.61 0 0 1-.988 1.483 1.595 1.595 0 0 1-1.743-.348A1.607 1.607 0 0 1 5.6 4.5a1.601 1.601 0 0 1 1.6 1.606Z"
                  clip-rule="evenodd"
                />
                <path d="M7.2 8.809H4V19.5h3.2V8.809Z" />
              </svg>
            </a>
            <a target="_blank" href="https://wa.me/6285921774621">
              <svg
                class="w-6 h-6 md:w-8 md:h-8 text-[#F4F3EE] transition ease-in-out duration-200 hover:-translate-y-1 hover:scale-110 hover:text-[#E0AFA0]"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="M12 4a8 8 0 0 0-6.895 12.06l.569.718-.697 2.359 2.32-.648.379.243A8 8 0 1 0 12 4ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.96 9.96 0 0 1-5.016-1.347l-4.948 1.382 1.426-4.829-.006-.007-.033-.055A9.958 9.958 0 0 1 2 12Z"
                  clip-rule="evenodd"
                />
                <path
                  fill="currentColor"
                  d="M16.735 13.492c-.038-.018-1.497-.736-1.756-.83a1.008 1.008 0 0 0-.34-.075c-.196 0-.362.098-.49.291-.146.217-.587.732-.723.886-.018.02-.042.045-.057.045-.013 0-.239-.093-.307-.123-1.564-.68-2.751-2.313-2.914-2.589-.023-.04-.024-.057-.024-.057.005-.021.058-.074.085-.101.08-.079.166-.182.249-.283l.117-.14c.121-.14.175-.25.237-.375l.033-.066a.68.68 0 0 0-.02-.64c-.034-.069-.65-1.555-.715-1.711-.158-.377-.366-.552-.655-.552-.027 0 0 0-.112.005-.137.005-.883.104-1.213.311-.35.22-.94.924-.94 2.16 0 1.112.705 2.162 1.008 2.561l.041.06c1.161 1.695 2.608 2.951 4.074 3.537 1.412.564 2.081.63 2.461.63.16 0 .288-.013.4-.024l.072-.007c.488-.043 1.56-.599 1.804-1.276.192-.534.243-1.117.115-1.329-.088-.144-.239-.216-.43-.308Z"
                />
              </svg>
            </a>
          </div>
        </div>
        <div className="absolute bottom-[14rem] right-12">
          <div className="flex flex-col space-y-3 md:space-y-4 items-center justify-center">
            <div className="hscroll-line"></div>
            <div className="w-2 h-2 md:w-4 md:h-4 border-2 bg-transparent border-[#F4F3EE] rounded-full animate-pulse"></div>
            <div className="w-2 h-2 md:w-3 md:h-3 border-2 bg-[#F4F3EE] border-[#F4F3EE] rounded-full animate-pulse"></div>
            <div className="w-2 h-2 md:w-3 md:h-3 border-2 bg-transparent border-[#F4F3EE] rounded-full animate-pulse"></div>
          </div>
        </div>
        <div className="absolute right-10 top-8 border-2 border-[#F4F3EE] py-2 px-4 z-30 transition duration-200 hover:scale-105">
          <a className="text-xl text-[#F4F3EE] underline underline-offset-4 font-semibold">
            Let's Talk
          </a>
        </div>
      </div>

      {/* ABOUT ME */}
      <div className="bg-[#F4F3EE] p-6 md:p-20 md:px-36 h-screen">
        {/* Desktop Layout */}
        <div className="hidden md:flex justify-center md:h-2/3 md:pt-40">
          <div className="absolute -z-1">
            <p className="font-outline text-[#F4F3EE] text-[4rem] md:text-[13rem] font-black opacity-30 tracking-wide -z-2 relative -mt-44">
              ABOUT ME
            </p>
            <div>
              <p className="text-[#e0afa0] text-[1.6rem] md:text-[2.6rem] font-bold tracking-normal mt-10 md:mt-28 relative z-20">
                CHYNTIA IRAWAN
              </p>
              <p className="text-[#463f3a87] text-md italic">
                Web Developer & Graphic Designer
              </p>
            </div>
            <div className="flex flex-row pt-10 items-center">
              <a
                href="./ChyntiaIrawan_CV.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-[#e0afa0] rounded-full px-6 py-2 text-[#F4F3EE] text-sm md:text-xl font-semibold mr-20 drop-shadow-lg z-20"
              >
                Download CV
              </a>
            </div>
          </div>
          <div className="flex pl-10 md:pl-64 -mt-[10rem] md:mt-0 justify-center items-center z-10">
            <img
              className="h-[15rem] md:h-[40rem] w-auto object-cover"
              src="./images/tia_white.webp"
              alt="image description"
            />
          </div>
          <div className="pt-20 w-[48%] z-20">
            <hr className="border-t-8 border-[#e0afa0] mt-4" />
            <p className="text-[#463F3A] text-sm mt-8 leading-normal hidden md:block">
              Studying in Informatics major with practical experience in both
              web development and social media. Additionally, freelanced not
              only as a customer service representative but also in social media
              management and graphic design, honing my communication,
              problem-solving skills, and creative skills.
            </p>
            <p className="text-[#463F3A] text-sm pt-3 leading-normal">
              I am constantly seeking opportunities to expand my knowledge and
              deliver high-quality results in the field of social media and web
              development department.
            </p>
            <hr className="border-t-2 border-gray-300 mt-4 pb-4" />
            <div className="grid grid-cols-2 grid-rows-2 gap-y-4 gap-x-0 z-40">
              <p className="text-sm text-[#463F3A]">
                <span className="font-semibold pr-2">Name:</span> Chyntia Irawan
              </p>
              <p className="text-sm text-[#463F3A]">
                <span className="font-semibold pr-2">Email: </span>
                <a
                  href="mailto:chyntia2104irawan@gmail.com"
                  className="text-[#e0afa0] font-semibold"
                >
                  chyntia2104irawan@gmail.com
                </a>
              </p>
              <p className="text-sm text-[#463F3A]">
                <span className="font-semibold pr-2">Age:</span> 20
              </p>
              <p className="text-sm text-[#463F3A]">
                <span className="font-semibold pr-2">Location:</span> Indonesia,
                Tangerang
              </p>
            </div>
          </div>
        </div>

        {/* Mobile Layout */}
        <div className="block md:hidden items-center justify-center h-screen">
          <div className="flex flex-col">
            <p className="font-outline text-[#F4F3EE] text-[3rem] font-black opacity-30 tracking-wide -z-2 relative">
              ABOUT ME
            </p>
            <div>
              <p className="text-[#e0afa0] text-[1.6rem] md:text-[2.6rem] font-bold tracking-normal relative z-20">
                CHYNTIA IRAWAN
              </p>
              <p className="text-[#463f3a87] text-md italic">
                Web Developer & Graphic Designer
              </p>
            </div>
            <div className="flex flex-row pt-10 items-center">
              <a
                href="./ChyntiaIrawan_CV.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-[#e0afa0] rounded-full px-6 py-2 text-[#F4F3EE] text-sm md:text-xl font-semibold mr-20 drop-shadow-lg z-20"
              >
                Download CV
              </a>
            </div>
          </div>
          <div className="mt-8">
            <hr className="border-t-8 border-[#e0afa0]" />
            <p className="text-[#463F3A] text-sm mt-4 leading-normal">
              Studying in Informatics major with practical experience in both
              web development and social media. Freelanced as a customer service
              representative, social media manager, and graphic designer.
            </p>
            <hr className="border-t-2 border-gray-300 mt-4" />
            <div className="grid grid-cols-1 gap-y-4 mt-4">
              <p className="text-sm text-[#463F3A]">
                <span className="font-semibold pr-2">Name:</span> Chyntia Irawan
              </p>
              <p className="text-sm text-[#463F3A]">
                <span className="font-semibold pr-2">Email: </span>
                <a
                  href="mailto:chyntia2104irawan@gmail.com"
                  className="text-[#e0afa0] font-semibold"
                >
                  chyntia2104irawan@gmail.com
                </a>
              </p>
              <p className="text-sm text-[#463F3A]">
                <span className="font-semibold pr-2">Age:</span> 20
              </p>
              <p className="text-sm text-[#463F3A]">
                <span className="font-semibold pr-2">Location:</span> Indonesia,
                Tangerang
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* ABOUT ME */}

      {/* BORDER */}
      <div
        className="flex items-center justify-center h-72 bg-fixed bg-top md:bg-cover"
        style={{ backgroundImage: `url("../images/border.png")` }}
      >
        <div className="overflow-hidden">
          <div className="p-5 text-9xl text-transparent tracking-wider font-black font-bungee ">
            CHYNTIA IRAWAN
          </div>
        </div>
      </div>
      {/* BORDER */}

      {/* PROJECT SOCIAL MEDIA */}
      <div className="h-full w-full">
        <div className="h-screen sticky top-0 left-0 overflow-clip">
          <div
            className="bg-[#F4F3EE] flex flex-col md:flex-row justify-between overflow-hidden bg-cover bg-no-repeat relative h-screen"
            style={{ backgroundImage: "url('./images/backdrop.png')" }}
          >
            <div className="absolute -z-1 bottom-0 right-0">
              <img
                className="w-full object-cover"
                src="./images/triangle.png"
                alt="image description"
              />
            </div>
            <div className="pl-4 md:pl-36 pt-4">
              <div className="flex justify-start">
                <p className="font-outline text-[#F4F3EE] text-[3.5rem] md:text-[7.5rem] font-black opacity-30 tracking-wide -z-2 relative mt-4 md:mt-28">
                  PROJECTS
                </p>
              </div>

              <div className="flex justify-start">
                <p className="text-[#e0afa0] text-[2rem] md:text-[4rem] font-bold tracking-normal mt-0 md:-mt-8 relative z-10 align-middle">
                  <span className="hidden md:block text-[1.5rem] align-middle">
                    -----------{" "}
                  </span>
                  GRAPHIC DESIGN
                </p>
              </div>
              <div className="flex flex-row justify-start md:justify-end">
                <div className="flex flex-row pt-2 md:pt-10 items-center gap-1 md:-rotate-12 self-end md:pb-36">
                  <hr className="border border-[#463F3A]"/>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/chyntiairawann/"
                  >
                    <svg
                      className="w-6 h-6 md:w-8 md:h-8 text-[#463F3A] mr-2 transition ease-in-out duration-200 hover:-translate-y-1 hover:scale-110"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        fill-rule="evenodd"
                        d="M3 8a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5V8Zm5-3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3H8Zm7.597 2.214a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2h-.01a1 1 0 0 1-1-1ZM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/in/chyntia-irawan-676486225/"
                  >
                    <svg
                      class="w-6 h-6 md:w-8 md:h-8 text-[#463F3A] mr-2 transition ease-in-out duration-200 hover:-translate-y-1 hover:scale-110"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12.51 8.796v1.697a3.738 3.738 0 0 1 3.288-1.684c3.455 0 4.202 2.16 4.202 4.97V19.5h-3.2v-5.072c0-1.21-.244-2.766-2.128-2.766-1.827 0-2.139 1.317-2.139 2.676V19.5h-3.19V8.796h3.168ZM7.2 6.106a1.61 1.61 0 0 1-.988 1.483 1.595 1.595 0 0 1-1.743-.348A1.607 1.607 0 0 1 5.6 4.5a1.601 1.601 0 0 1 1.6 1.606Z"
                        clip-rule="evenodd"
                      />
                      <path d="M7.2 8.809H4V19.5h3.2V8.809Z" />
                    </svg>
                  </a>
                  <a target="_blank" href="https://wa.me/6285921774621">
                    <svg
                      class="w-6 h-6 md:w-8 md:h-8 text-[#463F3A] mr-2 transition ease-in-out duration-200 hover:-translate-y-1 hover:scale-110"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        fill-rule="evenodd"
                        d="M12 4a8 8 0 0 0-6.895 12.06l.569.718-.697 2.359 2.32-.648.379.243A8 8 0 1 0 12 4ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.96 9.96 0 0 1-5.016-1.347l-4.948 1.382 1.426-4.829-.006-.007-.033-.055A9.958 9.958 0 0 1 2 12Z"
                        clip-rule="evenodd"
                      />
                      <path
                        fill="currentColor"
                        d="M16.735 13.492c-.038-.018-1.497-.736-1.756-.83a1.008 1.008 0 0 0-.34-.075c-.196 0-.362.098-.49.291-.146.217-.587.732-.723.886-.018.02-.042.045-.057.045-.013 0-.239-.093-.307-.123-1.564-.68-2.751-2.313-2.914-2.589-.023-.04-.024-.057-.024-.057.005-.021.058-.074.085-.101.08-.079.166-.182.249-.283l.117-.14c.121-.14.175-.25.237-.375l.033-.066a.68.68 0 0 0-.02-.64c-.034-.069-.65-1.555-.715-1.711-.158-.377-.366-.552-.655-.552-.027 0 0 0-.112.005-.137.005-.883.104-1.213.311-.35.22-.94.924-.94 2.16 0 1.112.705 2.162 1.008 2.561l.041.06c1.161 1.695 2.608 2.951 4.074 3.537 1.412.564 2.081.63 2.461.63.16 0 .288-.013.4-.024l.072-.007c.488-.043 1.56-.599 1.804-1.276.192-.534.243-1.117.115-1.329-.088-.144-.239-.216-.43-.308Z"
                      />
                    </svg>
                  </a>
                  <a target="_blank" href="https://github.com/Tiaa21">
                    <svg
                      class="w-6 h-6 md:w-8 md:h-8 text-[#463F3A] mr-2 transition ease-in-out duration-200 hover:-translate-y-1 hover:scale-110"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12.006 2a9.847 9.847 0 0 0-6.484 2.44 10.32 10.32 0 0 0-3.393 6.17 10.48 10.48 0 0 0 1.317 6.955 10.045 10.045 0 0 0 5.4 4.418c.504.095.683-.223.683-.494 0-.245-.01-1.052-.014-1.908-2.78.62-3.366-1.21-3.366-1.21a2.711 2.711 0 0 0-1.11-1.5c-.907-.637.07-.621.07-.621.317.044.62.163.885.346.266.183.487.426.647.71.135.253.318.476.538.655a2.079 2.079 0 0 0 2.37.196c.045-.52.27-1.006.635-1.37-2.219-.259-4.554-1.138-4.554-5.07a4.022 4.022 0 0 1 1.031-2.75 3.77 3.77 0 0 1 .096-2.713s.839-.275 2.749 1.05a9.26 9.26 0 0 1 5.004 0c1.906-1.325 2.74-1.05 2.74-1.05.37.858.406 1.828.101 2.713a4.017 4.017 0 0 1 1.029 2.75c0 3.939-2.339 4.805-4.564 5.058a2.471 2.471 0 0 1 .679 1.897c0 1.372-.012 2.477-.012 2.814 0 .272.18.592.687.492a10.05 10.05 0 0 0 5.388-4.421 10.473 10.473 0 0 0 1.313-6.948 10.32 10.32 0 0 0-3.39-6.165A9.847 9.847 0 0 0 12.007 2Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                  <a target="_blank" href="mailto:chyntia2104irawan@gmail.com">
                    <svg
                      class="w-6 h-6 md:w-8 md:h-8 text-[#463F3A] mr-2 transition ease-in-out duration-200 hover:-translate-y-1 hover:scale-110"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12.037 21.998a10.313 10.313 0 0 1-7.168-3.049 9.888 9.888 0 0 1-2.868-7.118 9.947 9.947 0 0 1 3.064-6.949A10.37 10.37 0 0 1 12.212 2h.176a9.935 9.935 0 0 1 6.614 2.564L16.457 6.88a6.187 6.187 0 0 0-4.131-1.566 6.9 6.9 0 0 0-4.794 1.913 6.618 6.618 0 0 0-2.045 4.657 6.608 6.608 0 0 0 1.882 4.723 6.891 6.891 0 0 0 4.725 2.07h.143c1.41.072 2.8-.354 3.917-1.2a5.77 5.77 0 0 0 2.172-3.41l.043-.117H12.22v-3.41h9.678c.075.617.109 1.238.1 1.859-.099 5.741-4.017 9.6-9.746 9.6l-.215-.002Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </div>
                <div className="flex justify-end items-center z-10 pr-24 -mt-16 relative">
                  <img
                    className="h-[27rem] w-auto object-cover hidden md:block"
                    src="./images/tia_pink.webp"
                    alt="image description"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-center items-center gap-4 pr-0 md:pr-24 pt-10 md:pt-0">
              <div className="md:-rotate-6 w-[10rem] md:w-[15rem]">
                <Slider {...settings_2} className="-translate-y-[5%]">
                  <div className="pb-2 w-[10rem] md:w-[15rem]">
                    <img
                      className="filter brightness-90 h-[8rem] md:h-[15rem] w-[8rem] md:w-[15rem] object-cover"
                      src="./images/mau_1.webp"
                      alt="image description"
                    />
                  </div>
                  <div className="pb-2 w-[5rem] md:w-[15rem]">
                    <img
                      className="filter brightness-90 h-[16rem] md:h-[20rem] w-[10rem] md:w-[15rem] object-cover"
                      src="./images/ssc_1.webp"
                      alt="image description"
                    />
                  </div>
                  <div className="pb-2 w-[5rem] md:w-[15rem]">
                    <img
                      className="filter brightness-90 h-[8rem] md:h-[15rem] w-[8rem] md:w-[15rem] object-cover"
                      src="./images/bb_1.webp"
                      alt="image description"
                    />
                  </div>
                  <div className="pb-2 w-[5rem] md:w-[15rem]">
                    <img
                      className="filter brightness-90 h-[16rem] md:h-[20rem] w-[10rem] md:w-[15rem] object-cover"
                      src="./images/ssc_2.webp"
                      alt="image description"
                    />
                  </div>
                  <div className="pb-2 w-[5rem] md:w-[15rem]">
                    <img
                      className="filter brightness-90 h-[8rem] md:h-[15rem] w-[8rem] md:w-[15rem] object-cover"
                      src="./images/bb_3.webp"
                      alt="image description"
                    />
                  </div>
                </Slider>
              </div>
              <div className="md:-rotate-6 w-[10rem] md:w-[15rem]">
                <Slider {...settings} className="-translate-y-[5%]">
                  <div className="pb-2 w-[10rem] md:w-[15rem]">
                    <img
                      className="filter brightness-90 h-[8rem] md:h-[15rem] w-[8rem] md:w-[15rem] object-cover"
                      src="./images/bb_2.webp"
                      alt="image description"
                    />
                  </div>
                  <div className="pb-2 w-[5rem] md:w-[15rem]">
                    <img
                      className="filter brightness-90 h-[16rem] md:h-[20rem] w-[10rem] md:w-[15rem] object-cover"
                      src="./images/ssc_3.webp"
                      alt="image description"
                    />
                  </div>
                  <div className="pb-2 w-[5rem] md:w-[15rem]">
                    <img
                      className="filter brightness-90 h-[8rem] md:h-[15rem] w-[8rem] md:w-[15rem] object-cover"
                      src="./images/bb_4.webp"
                      alt="image description"
                    />
                  </div>
                  <div className="pb-2 w-[5rem] md:w-[15rem]">
                    <img
                      className="filter brightness-90 h-[8rem] md:h-[15rem] w-[8rem] md:w-[15rem] object-cover"
                      src="./images/mau_2.webp"
                      alt="image description"
                    />
                  </div>
                  <div className="pb-2 w-[5rem] md:w-[15rem]">
                    <img
                      className="filter brightness-90 h-[8rem] md:h-[15rem] w-[8rem] md:w-[15rem] object-cover"
                      src="./images/mau_3.webp"
                      alt="image description"
                    />
                  </div>
                  <div className="pb-2 w-[5rem] md:w-[15rem]">
                    <img
                      className="filter brightness-90 h-[8rem] md:h-[15rem] w-[8rem] md:w-[15rem] object-cover"
                      src="./images/bb_5.webp"
                      alt="image description"
                    />
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
        {/* PROJECT SOCIAL MEDIA */}

        {/* PROJECT WEBSITE */}
        <div className="h-screen sticky top-0 left-0 bg-white brightness-90">
          <Slider ref={sliderRef} {...settings_website}>
            {website_porto.map((website_portos, index) => (
              <div key={index} className="flex h-screen relative bg-white">
                <div className="absolute md:mt-0 mt-6 text-[4rem] md:text-[16.5rem] font-bold z-10 pointer-events-none text-nowrap">
                  <p className={`opacity-50 ${website_portos.web_text_color}`}>
                    {website_portos.web_title}
                  </p>
                </div>
                <div
                  className={`flex flex-col md:flex-row gap-1 md:gap-20 px-6 md:px-24 h-screen ${website_portos.web_color} text-[#F4F3EE]`}
                >
                  <div className="w-full md:w-3/4 md:p-20 z-20 pt-8">
                    <img className="" src={website_portos.web_img} alt="" />
                  </div>
                  <div className="flex items-end w-full md:w-2/4 md:pb-14 relative">
                    <div className="grid grid-col gap-3 z-20">
                      <p className="text-lg">{website_portos.web_jobdesk}</p>
                      <p className="font-bold text-[1.1rem] md:text-[2.5rem]">
                        {website_portos.web_name}
                      </p>
                      <p className="font-light text-xs md:text-md">
                        {website_portos.web_desc}
                      </p>
                      <a
                        href={website_portos.web_link}
                        target="_blank"
                        className={`relative z-[10] hover:scale-105 cursor-pointer text-center p-1.5 md:p-3 rounded-full w-2/5 mt-3 drop-shadow-xl ${website_portos.web_button_color} text-[#F4F3EE] transition duration-200`}
                      >
                        See Project
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
          <button
            className="button absolute bottom-[2.5rem] left-64 md:left-8"
            onClick={previous}
          >
            <svg
              class="w-8 h-8 md:w-20 md:h-20 text-[#f4f3ee]"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m15 19-7-7 7-7"
              />
            </svg>
          </button>
          <button
            className="button absolute bottom-[2.5rem] right-8"
            onClick={next}
          >
            <svg
              class="w-8 h-8 md:w-20 md:h-20 text-[#f4f3ee]"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m9 5 7 7-7 7"
              />
            </svg>
          </button>
        </div>
        {/* PROJECT WEBSITE */}
      </div>

      {/* FOOTER */}
      <div className="flex flex-col md:flex-row bg-[#e0afa0] h-screen relative overflow-hidden">

        <div className="absolute z-10 flex w-full">
          <p className="px-8 md:px-0 leading-none font-outline-2 text-transparent text-[4rem] md:text-[10rem] font-black tracking-wide relative mt-10 md:mt-10 md:left-40 text-left">
            GET IN TOUCH
          </p>
        </div>

        <div className="w-full md:w-1/2 relative">
          <div className="bg-[#e0afa0] w-full h-full opacity-50 absolute z-0"></div>
          <img
            className="object-cover w-full h-[50vh] md:h-full object-center md:object-right"
            src="./images/tia_main.webp"
            alt=""
          />
        </div>

        <div className="flex w-full md:w-1/2 items-center mt-4 md:mt-20 px-4 md:ml-20 z-10">
          <div className="flex flex-col w-full">
            <p className="text-[#f4f3ee] text-[1.5rem] sm:text-[2rem] font-bold tracking-wide relative pb-4 text-center md:text-left">
              Contact Info
            </p>

            <div className="grid grid-cols-1 sm:grid-cols-2 grid-rows-auto gap-y-6 sm:gap-y-10 gap-x-4">
              <p className="text-sm sm:text-md text-[#f4f3ee]">
                <span className="font-semibold pr-2">Instagram:</span>
                <a
                  target="_blank"
                  href="https://www.instagram.com/chyntiairawann/"
                >
                  @chyntiairawann
                </a>
              </p>
              <p className="text-sm sm:text-md text-[#f4f3ee]">
                <span className="font-semibold pr-2">Email: </span>
                <a
                  href="mailto:chyntia2104irawan@gmail.com"
                  className="font-semibold"
                >
                  chyntia2104irawan@gmail.com
                </a>
              </p>
              <p className="text-sm sm:text-md text-[#f4f3ee]">
                <span className="font-semibold pr-2">LinkedIn:</span>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/chyntia-irawan-676486225/"
                >
                  Chyntia Irawan
                </a>
              </p>
              <p className="text-sm sm:text-md text-[#f4f3ee]">
                <span className="font-semibold pr-2">Phone Number:</span>
                <a target="_blank" href="https://wa.me/6285921774621">
                  +62 85921774621
                </a>
              </p>
            </div>

            <div className="border-t-2 border-[#f4f3ee] border-dashed my-6 sm:my-10"></div>

            <p className="italic font-semibold text-center text-[#f4f3ee]">
              Let's Create Something Amazing Together!
            </p>
          </div>
        </div>
      </div>
      {/* FOOTER */}
    </div>
  );
}

export default App;
